import React from 'react';

import { Card, CardContent, Skeleton } from '@mui/material';

const AboutSkeleton = () => (
    <Card variant="outlined" sx={{ borderRadius: 4, boxShadow: 4 }}>
        <CardContent>
            <Skeleton height="40px" width="50%" />
            <Skeleton width="60%" />
            <Skeleton width="75%" />
            <Skeleton width="50%" />
        </CardContent>
    </Card>
);

export default AboutSkeleton;
