// Home.js
import React from 'react';
import Content from '../components/Content';
import Layout from '../components/Layout';

const HomePage = () => {
    return (
        <Layout>
            <Content />
        </Layout>
    );
};

export default HomePage;
