import { createContext, useState } from 'react';

export const LoaderContext = createContext({
    showLoader: false,
    setShowLoader: () => {},
    loaderMessage: 'Loading',
    setLoaderMessage: () => {},
    loadingDuration: 0,
    setLoadingDuration: () => {},
});

/**
 * Provides a context for managing the loader state and props.
 *
 * The loader context provider allows us to manage the state of the loader
 * (whether it is visible or not) and props (the message to display and the
 * duration of the loading animation) in a single place. This makes it easier
 * to manage the state of the loader across different components.
 *
 * @param children - The components that should be wrapped by the loader
 *                    context provider.
 * @returns A context provider that wraps the given children with the loader
 *          context.
 */
export const LoaderProvider = ({ children }) => {
    const [showLoader, setShowLoader] = useState(false);
    const [loaderMessage, setLoaderMessage] = useState('Loading');
    const [loadingDuration, setLoadingDuration] = useState(0);

    return (
        <LoaderContext.Provider
            value={{
                showLoader,
                setShowLoader,
                loaderMessage,
                setLoaderMessage,
                loadingDuration,
                setLoadingDuration,
            }}
        >
            {children}
        </LoaderContext.Provider>
    );
};
