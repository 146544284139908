/** 
 * @deprecated migarted from defaultLeftHeaders options to ? icons
 * save for later change in mind
 */
export const defaultLeftHeaderContent = [
    {
        key: 'email',
        name: 'Google Gmail',
        favicon: '/assets/images/communicator_icons/gmail.png',
        url: 'https://www.gmail.com',
    },
    {
        key: 'calendar',
        name: 'Google Calendar',
        favicon: '/assets/images/communicator_icons/googlecalender.png',
        url: 'https://calendar.google.com',
    },
    {
        key: 'contacts',
        name: 'Google Contacts',
        favicon: '/assets/images/communicator_icons/google_contact.png',
        url: 'https://contacts.google.com',
    },
    {
        key: 'cloud_drive',
        name: 'Google Drive',
        favicon: '/assets/images/communicator_icons/drive.png',
        url: 'https://drive.google.com',
    },
    {
        key: 'dropbox',
        name: 'Dropbox.com',
        favicon: '/assets/images/communicator_icons/dropbox.png',
        url: 'https://www.dropbox.com/',
    },
    {
        key: 'maps',
        name: 'Google Maps',
        favicon: '/assets/images/communicator_icons/google_map.png',
        url: 'https://maps.google.com',
    },
    {
        key: 'socialpilot',
        name: 'SocialPilot',
        favicon: '/assets/images/communicator_icons/socialpilot.png',
        url: 'https://www.socialpilot.co',
    },
    {
        key: 'paypal',
        name: 'PayPal',
        favicon: '/assets/images/communicator_icons/paypal.png',
        url: 'https://www.paypal.com/pk/home',
    },
    {
        key: 'quickbooks',
        name: 'QuickBooks',
        favicon: '/assets/images/communicator_icons/quick_books.png',
        url: 'https://quickbooks.intuit.com/global/',
    },
    {
        key: 'constant_contact',
        name: 'Constant Contact',
        favicon:
            '/assets/images/communicator_icons/constant_contact.png',
        url: 'https://constantcontact.com',
    },
    {
        key: 'godaddy_developer',
        name: 'GoDaddy Developer',
        favicon:
            '/assets/images/communicator_icons/godaddy_developer.png',
        url: 'https://developer.godaddy.com',
    },
    {
        key: 'zoom_phone',
        name: 'Zoom Phone',
        favicon: '/assets/images/communicator_icons/zoom_video.png',
        url: 'https://www.zoom.com/en/products/voip-phone',
    },
    {
        key: 'calcom_video',
        name: 'Cal.com Video',
        favicon: '/assets/images/communicator_icons/cal.png',
        url: 'https://cal.com/resources/feature/cal-video',
    },
    {
        key: 'dynamics365',
        name: 'Dynamics 365',
        favicon: '/assets/images/communicator_icons/microsoft365.png',
        url: 'https://www.microsoft365.com/',
    },
    {
        key: 'microsoft_teams',
        name: 'Microsoft Teams',
        favicon:
            '/assets/images/communicator_icons/microsoft_teams.png',
        url: 'https://teams.microsoft.com/',
    },
].map((header, index) => ({
    ...header,
    id: index + 1,
}))

/**
 * Generates an array of 15 empty header objects.
 *
 * @returns {Array<{id: number, key: string, name: string, favicon: string, url: string}>} 
 * An array of objects with default values.
 */
export const generateDefaultLeftHeadersEmpty = () => {
    const defaultHeaders = [];

    for (let i = 0; i < 15; i++) {
        defaultHeaders.push({
            id: i + 1,
            key: 'customize',
            name: 'Customize',
            favicon: "/assets/images/default_hlt.png",
            url: '',
        });
    }

    return defaultHeaders;
}