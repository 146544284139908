import { useContext, useEffect } from 'react';

import { LoaderContext } from '../context/LoaderContext';

/**
 * Hook to manage the loading state of the application.
 *
 * This hook provides methods to manually show and hide the loader.
 * If a duration is provided, the loader will automatically hide after that duration.
 *
 * @returns An object with two methods: `startLoading` and `stopLoading`.
 *   - `startLoading(message, duration)`: Shows the loader with the given message.
 *     If a duration is provided, the loader will automatically hide after that duration.
 *   - `stopLoading()`: Hides the loader.
 */

const useLoader = () => {
    const {
        showLoader,
        setShowLoader,
        setLoaderMessage,
        loadingDuration,
        setLoadingDuration,
    } = useContext(LoaderContext);

    useEffect(() => {
        let timer;
        if (showLoader && loadingDuration > 0) {
            timer = setTimeout(() => {
                if (loadingDuration > 0 && !showLoader) {
                    setShowLoader(false);
                }
            }, loadingDuration);
        }

        return () => clearTimeout(timer);
    }, [showLoader, loadingDuration, setShowLoader]);

    /**
     * Shows the loader with the given message.
     *
     * @param {{ message: string, duration: number|null }} [options]
     *   - `message`: The message to display on the loader.
     *   - `duration`: The duration in milliseconds to show the loader for.
     *     If omitted, the loader will be shown until `stopLoading` is called.
     */
    const startLoading = ({ message = 'Loading', duration = null } = {}) => {
        setLoaderMessage(message);
        if (duration !== null) {
            setLoadingDuration(duration);
        } else {
            setLoadingDuration(0);
        }
        setShowLoader(true);
    };

    /**
     * Hides the loader and stops any auto-hiding timer.
     *
     * Call this function when the task that the loader was indicating
     * is complete. If you used `startLoading` with a duration, you
     * don't need to call this function explicitly.
     */
    const stopLoading = () => {
        setShowLoader(false);
    };

    return { startLoading, stopLoading };
};

export default useLoader;
