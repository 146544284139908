import React, { createContext, useContext, useState, useCallback } from 'react';

const UpgradeModalContext = createContext();

export const useUpgradeModal = () => {
    return useContext(UpgradeModalContext);
};

export const UpgradeModalProvider = ({ children }) => {
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);

    const toggleUpgradeModal = useCallback(() => {
        setShowUpgradeModal((prev) => !prev);
    }, []);

    const value = {
        showUpgradeModal,
        toggleUpgradeModal,
    };

    return (
        <UpgradeModalContext.Provider value={value}>
            {children}
        </UpgradeModalContext.Provider>
    );
};
