import React from 'react';

import { Box, Card, CardContent, Skeleton } from '@mui/material';

const BasicInformationSkeleton = () => {
    const renderSkeletonRow = () => (
        <Box
            display="flex"
            mt={1}
            flexDirection="row"
            alignItems="center"
            gap={2}
        >
            <Skeleton variant="rounded" height={40} width={40} />
            <Box width="100%">
                <Skeleton height={20} width="60%" />
            </Box>
        </Box>
    );

    return (
        <Card variant="outlined" sx={{ borderRadius: 4, boxShadow: 4 }}>
            <CardContent>
                <Skeleton height={40} width="50%" />
                {renderSkeletonRow()}
                {renderSkeletonRow()}
                {renderSkeletonRow()}
                {renderSkeletonRow()}
                {renderSkeletonRow()}
                {renderSkeletonRow()}
            </CardContent>
        </Card>
    );
};

export default BasicInformationSkeleton;
