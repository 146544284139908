import React, { lazy } from 'react';

import {
    ContentCopyRounded,
    DownloadRounded,
    EmailRounded,
    FeedbackRounded,
    LinkRounded,
    PrintRounded,
    ShareRounded,
} from '@mui/icons-material';

import {
    qlToolbar,
    getNotakerName,
    qlBasicToolbar,
} from '../components/reusable/notetaker/NotakerSettings';
import createNotepadModules from '../components/reusable/notetaker/NotepadModules';
import { commonButtons } from './Buttons';

export const tempUnitOptions = [
    { label: 'Celsius ', value: 'celsius' },
    { label: 'Fahrenheit ', value: 'fahrenheit' },
];

const BottomToolbar = lazy(() =>
    import('../components/reusable/notetaker/BottomToolbar'),
);
const EditorToolBar = lazy(() =>
    import('../components/reusable/notetaker/EditorToolBar'),
);

export const toolbarWithoutDownload = {
    share: {
        icon: <ShareRounded />,
        actions: [
            {
                name: 'email',
                icon: <EmailRounded />,
                visible: true,
            },
            {
                name: 'feedback',
                icon: <FeedbackRounded />,
                visible: true,
            },
            {
                name: 'weblink',
                icon: <LinkRounded />,
                visible: true,
            },
        ],
    },
    recycle: {
        visible: true,
    },
    undo: {
        visible: true,
    },
};

export const globalToolBarVisibleBtns = {
    dropdown: {
        icon: <DownloadRounded />,
        actions: [
            {
                name: 'pdf',
                visible: true,
            },
        ],
    },
    share: {
        icon: <ShareRounded />,
        actions: [
            {
                name: 'email',
                icon: <EmailRounded />,
                visible: true,
            },
            {
                name: 'feedback',
                icon: <FeedbackRounded />,
                visible: true,
            },
            {
                name: 'weblink',
                icon: <LinkRounded />,
                visible: true,
            },
        ],
    },
    recycle: {
        visible: true,
    },
    undo: {
        visible: true,
    },
};

export const emailVisibleBtns = {
    // save: {
    //     visible: true,
    // },
    dropdown: {
        icon: <DownloadRounded />,
        actions: [
            {
                name: 'doc',
                visible: true,
            },
            {
                name: 'docx',
                visible: true,
            },
            {
                name: 'pdf',
                visible: true,
            },
            {
                name: 'wpd',
                visible: true,
            },
        ],
    },
    share: {
        icon: <ShareRounded />,
        actions: [
            {
                name: 'copy',
                icon: <ContentCopyRounded />,
                visible: true,
            },
            {
                name: 'email',
                icon: <EmailRounded />,
                visible: true,
            },
            {
                name: 'feedback',
                icon: <FeedbackRounded />,
                visible: true,
            },
            {
                name: 'print',
                icon: <PrintRounded />,
                visible: true,
            },
            {
                name: 'weblink',
                icon: <LinkRounded />,
                visible: true,
            },
        ],
    },
    recycle: {
        visible: true,
    },
    undo: {
        visible: true,
    },
};

export const generalVisibleBtns = {
    dropdown: {
        icon: <DownloadRounded />,
        actions: [
            {
                name: 'doc',
                visible: true,
            },
            {
                name: 'docx',
                visible: true,
            },
            {
                name: 'pdf',
                visible: true,
            },
            {
                name: 'wpd',
                visible: true,
            },
        ],
    },
    share: {
        icon: <ShareRounded />,
        actions: [
            {
                name: 'copy',
                icon: <ContentCopyRounded />,
                visible: true,
            },
            {
                name: 'email',
                icon: <EmailRounded />,
                visible: true,
            },
            {
                name: 'feedback',
                icon: <FeedbackRounded />,
                visible: true,
            },
            {
                name: 'print',
                icon: <PrintRounded />,
                visible: true,
            },
            {
                name: 'weblink',
                icon: <LinkRounded />,
                visible: true,
            },
        ],
    },
    recycle: {
        visible: true,
    },
    undo: {
        visible: true,
    },
};

export const basicToolBarVisibleBtns = {
    recycle: {
        visible: true,
    },
    undo: {
        visible: true,
    },
};

export const coverSheetVisibleBtns = {
    // save: {
    //     visible: true,
    // },
    dropdown: {
        icon: <DownloadRounded />,
        actions: [
            {
                name: 'pdf',
                visible: true,
            },
        ],
    },
    share: {
        icon: <ShareRounded />,
        actions: [
            {
                name: 'email',
                icon: <EmailRounded />,
                visible: true,
            },
            {
                name: 'feedback',
                icon: <FeedbackRounded />,
                visible: true,
            },
            {
                name: 'weblink',
                icon: <LinkRounded />,
                visible: true,
            },
        ],
    },
    recycle: {
        visible: true,
    },
    undo: {
        visible: true,
    },
};
export const toolbarSaveVisibleBtn = {
    save: {
        visible: true,
    },
};

export const shareVisibleBtn = {
    share: {
        icon: <ShareRounded />,
        actions: [
            {
                name: 'email',
                icon: <EmailRounded />,
                visible: true,
            },
            {
                name: 'feedback',
                icon: <FeedbackRounded />,
                visible: true,
            },
            {
                name: 'weblink',
                icon: <LinkRounded />,
                visible: true,
            },
        ],
    },
};

export const notepad = ({
    name,
    toolbarName,
    toolbarType,
    noTakerName,
    placeholder,
    require = false,
    bottomToolbar = false,
    label,
    extraTools = false,
}) => ({
    type: 'notepad',
    className: 'col-md-12 notepad',
    label: label,
    name: name,
    placeholder:
        placeholder ?? 'Type here, then select the below "Save" icons ...',
    required: require,
    editorToolBar: (
        <EditorToolBar
            name={name}
            qlToolbar={toolbarType === 'basic' ? qlBasicToolbar : qlToolbar}
            toolBarType={toolbarType}
            extraTools={extraTools}
        />
    ),
    notepadModules: createNotepadModules(getNotakerName(noTakerName)),
    notepadToolbar: getNotakerName(noTakerName),
    bottomToolbar: bottomToolbar ? <BottomToolbar /> : null,
});

export const notes = [
    notepad({
        name: 'notepad',
        toolbarName: 'notepad',
        toolbarType: 'advanced',
        noTakerName: 'notepad',
        require: false,
        bottomToolbar: true,
    }),
];

const fields = {
    name: {
        type: 'text',
        className: 'col-md-12',
        label: 'Name',
        name: 'name',
        placeholder: 'Name',
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
    },
    phone: {
        type: 'tel',
        className: 'col-md-12',
        label: 'Phone',
        name: 'phone_number',
        buttons: [...commonButtons('callBtn', 'pasteBtn', 'clearBtn')],
    },
    emergencyPhone: {
        type: 'tel',
        className: 'col-md-12',
        label: 'Emergency Phone',
        name: 'emergency_phone_number',
        buttons: [...commonButtons('callBtn', 'pasteBtn', 'clearBtn')],
    },
    email: {
        type: 'email',
        className: 'col-md-12',
        label: 'Email',
        name: 'email',
        buttons: [...commonButtons('emailBtn', 'pasteBtn', 'clearBtn')],
    },
    weblink: {
        type: 'url',
        className: 'col-md-12',
        label: 'Weblink',
        name: 'url',
        placeholder: 'http://www.example.com',

        buttons: [...commonButtons('launchBtn', 'pasteBtn', 'clearBtn')],
    },
    facebookweblink: {
        type: 'url',
        className: 'col-md-12',
        label: 'Facebook Weblink',
        name: 'url',
        placeholder: 'http://www.example.com',
        pattern: '^(http://www.).*',
        buttons: [...commonButtons('launchBtn', 'pasteBtn', 'clearBtn')],
    },
    linkedInweblink: {
        type: 'url',
        className: 'col-md-12',
        label: 'Linkedin Weblink',
        name: 'url',
        placeholder: 'http://www.example.com',
        pattern: '^(http://www.).*',
        buttons: [...commonButtons('launchBtn', 'pasteBtn', 'clearBtn')],
    },

    comments: {
        type: 'textarea',
        className: 'col-md-12',
        label: 'Comments',
        name: 'comments',
        placeholder: '',
        row: 3,
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
    },
    results: {
        type: 'textarea',
        className: 'col-md-12',
        label: 'Results',
        name: 'results',
        placeholder: '',
        row: 3,
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
    },
    arguments: {
        type: 'textarea',
        className: 'col-md-12',
        label: 'Arguments',
        name: 'arguments',
        placeholder: '',
        row: 3,
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
    },
    notes: {
        type: 'textarea',
        className: 'col-md-12',
        label: 'Notes',
        name: 'notes',
        placeholder: '',
        row: 3,
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
    },

    title: {
        type: 'text',
        className: 'col-md-12',
        label: 'Title',
        name: 'title',
        placeholder: '',
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
    },
    entry: {
        type: 'textarea',
        className: 'col-md-12',
        label: 'Entry',
        name: 'comments',
        placeholder: '',
    },
    emoji: {
        type: 'emoji',
        className: 'col-md-12',
        label: 'Emoji',
        name: 'emoji',
        placeholder: '',
        maxLength: 1,
    },
    jdate: {
        className: 'col-md-12',
        label: 'Date',
        name: 'date',
        placeholder: '',
        hidden: true,
    },
    jtime: {
        type: 'time',
        className: 'col-md-12',
        label: 'Time',
        name: 'time',
        placeholder: '',
        hidden: true,
    },
    tempUnit: {
        componentType: 'inputSelect',
        label: 'Temperature Unit',
        options: tempUnitOptions,
        required: true,
        useYupValidation: true,
        selectClass: '',
        selectName: 'tempUnit',
    },
    number: {
        type: 'number',
        className: 'col-md-12',
        label: 'Number',
        name: 'number',
        placeholder: '',
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
        useYupValidation: true,
        min: 0,
    },
    lastFourDigit: {
        type: 'text',
        className: 'col-md-12',
        label: 'Account Last Four Digits',
        name: 'four_digits',
        placeholder: '',
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
        formatting: false,
    },
};

export const commonFields = (...selectedFields) => {
    const filteredFieldValues = [];

    // Iterate over the selected fields and add their values to the filteredFieldValues array
    for (const field of selectedFields) {
        if (fields[field]) {
            filteredFieldValues.push(fields[field]);
        }
    }

    return filteredFieldValues;
};

export const commonFormFields = [
    ...commonFields('title', 'weblink', 'comments'),
    ...commonButtons('addBtn'),
];

export const your = [
    ...commonFields('title', 'weblink', 'comments'),
    ...commonButtons('addBtn'),
];
