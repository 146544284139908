import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';

import { ErrorRounded } from '@mui/icons-material';

import LogoutButton from '../settings/accountInformation/LogoutButton';
import { handleMailTo } from '../../utils/Utils';

const ErrorBoundaryFallback = () => {
    const { isAuthenticated } = useAuth0();
    const siteUrl = process.env.REACT_APP_SITE_URL;

    const handleReload = () => {
        window.location.reload();
    };

    const handleNavigateHome = () => {
        window.location.href = siteUrl;
    };

    const handleEmail = () => {
        const email = 'support@toolkit.law';
        const subject = 'Toolkit.law Support Request';
        const body =
            'I am encountering an issue and would like assistance related to the following issue';
        handleMailTo(subject, body, email);
    };

    return (
        <Container
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Grid container mt={4} justifyContent="center" alignItems="center">
                <Grid item xs={12} md={8} textAlign="center">
                    <Box
                        sx={{
                            maxWidth: 500,
                            mx: 'auto',
                        }}
                    >
                        <Paper
                            elevation={3}
                            sx={{ p: 4, borderRadius: 2, textAlign: 'center' }}
                        >
                            <ErrorRounded
                                sx={{
                                    fontSize: 60,
                                    color: 'primary.main',
                                    mb: 2,
                                }}
                            />
                            <Typography
                                variant="h4"
                                component="h3"
                                gutterBottom
                            >
                                Something went wrong.
                            </Typography>
                            <Typography sx={{ mb: 3, textAlign: 'center' }}>
                                Please try refreshing the page, return home, or
                                contact our support team for assistance.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    gap: 2,
                                    flexWrap: 'wrap',
                                }}
                            >
                                <Button variant="dark" onClick={handleReload}>
                                    Reload
                                </Button>
                                <Button
                                    variant="dark"
                                    onClick={handleNavigateHome}
                                >
                                    Home
                                </Button>
                                {isAuthenticated ? (
                                    <LogoutButton variant="dark" />
                                ) : (
                                    <Button
                                        variant="dark"
                                        onClick={handleEmail}
                                    >
                                        Support
                                    </Button>
                                )}
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ErrorBoundaryFallback;
