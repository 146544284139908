import React, { useState } from 'react';
import { Box, LinearProgress } from '@mui/material';

const SplashScreen = () => {
    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#f5f5f5', // Set a light background color for contrast
                position: 'fixed', // Fix position to the viewport
                top: 0,
                left: 0,
            }}
        >
            <Box
                sx={{
                    width: 200, // Set the width of the outer box
                    height: 200, // Set the height of the outer box
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box
                    component="img"
                    src="https://admin.toolkit.law/storage/images/1728392473.svg"
                    alt="Logo"
                    sx={{ maxWidth: '80%', height: '150px' }}
                    onLoad={() => setImageLoaded(true)} // Set imageLoaded to true when the image is loaded
                />
                {/* Display the progress bar below the image once the image is loaded */}
                {imageLoaded && (
                    <LinearProgress
                        sx={{ width: '80%', height: '5px', mt: 2 }}
                    />
                )}
            </Box>
        </Box>
    );
};

export default SplashScreen;
