import axios from 'axios';
import { Navigate } from 'react-router-dom';
export const customLogin = async (email, oauth0Id) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}custom-login`,
            {
                email: email,
                auth0_id: oauth0Id,
            },
        );

        const data = response.data;

        if (response.status === 200) {
            // Successful login, store the token and its expiration time in sessionStorage
            const { token, expiry, userId, status, isPaid, trial } = data.data;
            const expiryDate = new Date(expiry);
            // Calculate the time difference in seconds between the current time and the expiry time.
            const timeDifferenceInSeconds =
                (expiryDate.getTime() - new Date().getTime()) / 1000;

            const expirationTime =
                new Date().getTime() + timeDifferenceInSeconds * 1000;
            sessionStorage.setItem('accessToken', token);
            sessionStorage.setItem(
                'tokenExpiration',
                expirationTime.toString(),
            );
            sessionStorage.setItem('userId', userId);

            //store in local storage because session storage clear in new tab.
            //local storage store Shared across all tabs/windows
            localStorage.setItem('userStatus', status.toString());
            localStorage.setItem('userPd', isPaid.toString());
            localStorage.setItem('trial', trial.toString());

            if (isPaid === 0) {
                return <Navigate to="/settings/billing/plans" />;
            } else {
                return { accessToken: token, userId: userId };
            }
        } else {
            // Login failed, handle error
            console.error('Login failed:', data.message);
            return null;
        }
    } catch (error) {
        console.error('Error occurred:', error);
    }
};

export const getAuthData = async (email, oauth0Id) => {
    // Check if access token and its expiration time exist in sessionStorage
    const existingToken = sessionStorage.getItem('accessToken');
    const expirationTime = sessionStorage.getItem('tokenExpiration');

    if (existingToken && expirationTime) {
        const currentTime = new Date().getTime();
        // If the token has not expired, return the existing access token
        if (parseInt(expirationTime) > currentTime) {
            const userId = sessionStorage.getItem('userId');
            return { accessToken: existingToken, userId: userId };
        }
    }

    try {
        // If the token has expired or is not present, get a new token using customLogin
        return await customLogin(email, oauth0Id);
    } catch (error) {
        console.error('Error occurred while getting access token:', error);
        throw error; // Rethrow the error for the calling code to handle
    }
};

/**
 * Gets the access token and user ID from the server using the provided user object.
 *
 * @param {Object} user The user object containing the email and auth0 ID.
 *
 * @returns {Object} An object containing the access token and user ID.
 *
 * @throws {Error} If no access token is available.
 */
export const getAccessTokenAndUserId = async (user) => {
    const { email, sub: auth0id } = user;
    const authData = await getAuthData(email, auth0id);
    const { accessToken, userId } = authData;

    if (!accessToken) {
        throw new Error('No access token available.');
    }

    return { accessToken, userId };
};
