import React, { createContext, useContext, useState, useCallback } from 'react';

const AccountModalContext = createContext();

export const useModal = () => {
    return useContext(AccountModalContext);
};

export const AccountModalProvider = ({ children }) => {
    const [showModalAccount, setShowModalAccount] = useState(false);

    const toggleModalAccount = useCallback(() => {
        setShowModalAccount((prev) => !prev);
    }, []);

    const value = {
        showModalAccount,
        toggleModalAccount,
    };

    return (
        <AccountModalContext.Provider value={value}>
            {children}
        </AccountModalContext.Provider>
    );
};
