import React from 'react';

import { Card, CardContent, Skeleton } from '@mui/material';

const ToolkitLinksSkeleton = () => {
    const skeletonRows = Array(9).fill(null); // Create an array with 5 elements

    return (
        <Card variant="outlined" sx={{ boxShadow: 4, borderRadius: 4 }}>
            <CardContent>
                <Skeleton variant="rounded" height={40} width="50%" />
                {skeletonRows.map((_, index) => (
                    <Skeleton key={index} width="60%" sx={{ my: 1 }} />
                ))}
            </CardContent>
        </Card>
    );
};

export default ToolkitLinksSkeleton;
