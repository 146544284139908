import React from 'react';

import { Box, Skeleton } from '@mui/material';

const CopyRightSkeleton = () => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
        >
            <Skeleton width="50%" />
        </Box>
    );
};

export default CopyRightSkeleton;
