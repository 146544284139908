import {
    Add,
    DragHandle,
    Close,
    ContentCopy,
    ContentPaste,
    Edit,
    Email,
    HelpRounded,
    Image,
    Launch,
    LocationOn,
    Phone,
    Undo,
} from '@mui/icons-material';

const buttons = {
    addBtn: {
        type: 'button',
        id: '',
        className: 'col-md-12 d-flex justify-content-center my-2',
        class: 'btn addBtn btn-sm',
        btnClass: 'btn addBtn btn-sm',
        name: 'Add',
        title: 'Add',
        imgSrc: <Add />,
    },
    pasteBtn: {
        label: 'Paste',
        id: '',
        class: 'btn pasteBtn btn-sm',
        title: 'Paste',
        imgSrc: <ContentPaste />,
        type: 'button',
    },
    clearBtn: {
        label: 'Clear',
        id: '',
        class: 'btn btn-sm',
        name: 'Clear',
        title: 'Clear',
        imgSrc: <Close />,
        type: 'button',
    },
    callBtn: {
        label: 'Call',
        id: '',
        class: 'btn callBtn btn-sm',
        title: 'Call',
        imgSrc: <Phone />,
        type: 'button',
    },
    launchBtn: {
        label: 'Open in New Tab',
        id: '',
        class: 'btn launchBtn btn-sm',
        title: 'Launch',
        imgSrc: <Launch />,
        type: 'button',
    },
    launchMapBtn: {
        label: 'Open in New Tab',
        id: '',
        class: 'btn mapBtn btn-sm',
        title: 'LaunchMap',
        imgSrc: <LocationOn />,
        type: 'button',
    },
    emailBtn: {
        label: 'Open in New Tab',
        id: '',
        class: 'btn emailBtn btn-sm',
        title: 'Email',
        imgSrc: <Email />,
        type: 'button',
    },
    pdfButton: {
        type: 'pdfButton',
        className: 'col-md-12 mt-2 d-flex justify-content-center',
        btnClass: 'btn pdfBtn',
        imgSrc: (
            <img
                src="/assets/images/global_icons/pdf.png"
                alt="Pdf Icon"
                className="icons"
            />
        ),
        name: 'PDF',
    },
    docButton: {
        type: 'button',
        className: 'col-md-12 d-flex justify-content-center',
        btnClass: 'btn',
        imgSrc: (
            <img
                src="/assets/images/global_icons/docx.png"
                alt="Pdf Icon"
                className="icons"
            />
        ),
        name: 'DOC',
    },
    pdfAllButton: {
        type: 'pdfButton',
        className: 'col-md-12 mt-2 d-flex justify-content-center',
        btnClass: 'btn pdfBtn',
        name: 'PDF ALL',
        imgSrc: (
            <img
                src="/assets/images/global_icons/pdf.png"
                alt="Pdf Icon"
                className="icons"
            />
        ),
    },
    undoBtn: {
        label: 'Undo',
        id: '',
        class: 'btn undoBtn btn-sm',
        title: 'Undo',
        imgSrc: <Undo />,
        type: 'button',
    },
    editBtn: {
        label: 'Edit',
        id: '',
        class: 'btn editBtn',
        name: 'Edit',
        imgSrc: <Edit />,
        type: 'button',
    },
    submitBtn: {
        label: 'Submit',
        id: '',
        class: 'btn submitBtn',
        name: 'submit',
        imgSrc: <DragHandle />,
        type: 'button',
    },
    copyBtn: {
        label: 'Copy',
        id: '',
        class: 'btn copyBtn btn-sm',
        name: 'Copy',
        title: 'Copy',
        imgSrc: <ContentCopy />,
        type: 'button',
    },
    pngBtn: {
        label: 'PNG Image',
        id: '',
        class: 'btn imageBtn',
        name: 'PNG Image',
        imgSrc: <Image />,
        type: 'button',
    },
    helpBtn: {
        label: 'Help',
        id: '',
        class: 'btn btn-sm',
        name: 'Help',
        title: 'Help',
        imgSrc: <HelpRounded />,
        type: 'button',
    },
};

export const commonButtons = (...selectedFields) => {
    const filteredButtons = [];

    // Iterate over the selected fields and add their values to the filteredFieldValues array
    for (const button of selectedFields) {
        if (buttons[button]) {
            filteredButtons.push(buttons[button]);
        }
    }

    return filteredButtons;
};
