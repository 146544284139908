import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import 'animate.css/animate.css';
import 'bootstrap/dist/js/bootstrap.js';
import './css/style.css';
import './css/button.css';
import Auth0ProviderWithHistory from './auth0/Auth0Provider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration'; // <-- Import service worker registration

function getUrlVars() {
    const params = new URLSearchParams(window.location.search);
    const vars = {};
    for (const [key, value] of params.entries()) {
        vars[key] = value;
    }
    return vars;
}

var urlParams = getUrlVars();
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

switch (urlParams['entryPoint']) {
    default:
        root.render(
            <Router>
                <Auth0ProviderWithHistory>
                    <App />
                </Auth0ProviderWithHistory>
            </Router>,
        );
        break;
}

// Enable the service worker for PWA support
serviceWorkerRegistration.register(); //

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
