import 'react-quill/dist/quill.snow.css'; // Import React Quill styles
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';

// Redo button icon component for Quill editor
export const CustomRedo = () => <RedoIcon fontSize="medium" />;

export function handleUndoChange() {
    this.quill.history.undo();
}

export function handleRedoChange() {
    this.quill.history.redo();
}

export const CustomUndo = () => <UndoIcon />;

const createNotepadModules = (notepadName) => {
    const notepadModules = {
        toolbar: {
            container: `#${notepadName}`,
            handlers: {
                redo: handleRedoChange, // Make sure handleRedoChange is defined
                undo: handleUndoChange, // Make sure handleUndoChange is defined
            },
        },
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true,
        },
    };

    return notepadModules;
};

export default createNotepadModules;
