import { Container, Grid } from '@mui/material';

import HeadingSkeleton from './HeadingSkeleton';
import AboutSkeleton from './AboutSkeleton';
import ServicesSkeleton from './ServicesSkeleton';
import EducationSkeleton from './EducationSkeleton';
import BasicInformationSkeleton from './BasicInformationSkeleton';
import ToolkitLinksSkeleton from './ToolkitLinksSkeleton';
import CopyRightSkeleton from './CopyRightSkeleton';

const ProfilePageSkeleton = () => {
    return (
        <Container maxWidth="lg" sx={{ py: 3 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <HeadingSkeleton />
                </Grid>
                <Grid item xs={12}>
                    <AboutSkeleton />
                </Grid>
                <Grid item xs={12}>
                    <ServicesSkeleton />
                </Grid>
                <Grid item xs={12}>
                    <EducationSkeleton />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <BasicInformationSkeleton />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <ToolkitLinksSkeleton />
                </Grid>
                <Grid item xs={12}>
                    <CopyRightSkeleton />
                </Grid>
            </Grid>
        </Container>
    );
};

export default ProfilePageSkeleton;
