import { commonButtons } from '../../../../config/Buttons';
import { formFieldswithNotepad } from '../../../../utils/fieldsHelper';

const imageURL = process.env.REACT_APP_THEME_ICON_URL;

export const notepadPlaceHolerText =
    'Type here, then select the below "Save" icons ...';
export const googleChatUrl = 'https://mail.google.com/chat';
export const fbUrl = 'https://www.facebook.com';
export const yourtubeUrl = 'https://www.youtube.com';
export const linkedinUrl = 'https://www.linkedin.com';
export const socailPilotUrl = 'https://www.socialpilot.co';
export const instagramUrl = 'https://www.instagram.com';
export const twitterUrl = 'https://twitter.com';
export const whatsappUrl = 'https://web.whatsapp.com';
export const subject = 'Message Generated by Toolkit.law';
export const fromEmail = 'toolkit@toolkit.law';
export const unDoAbleOptions = {
    behavior: 'mergePastReversed',
    historyLimit: 1,
};
export const linkedinTitle = 'LinkedIn Share';
export const whatsappTitle = 'WhatsApp Share';
export const youtubeTitle = 'YouTube Share';
export const socialpilotTitle = 'SocialPilot Share';
export const notepadDeleteTitle = 'Delete Entire Note';
export const notepadPdfButtonTitle = 'Save as PDF';
export const notepadDocButtonTitle = 'Save as Word';
export const notepadEmailButtonTitle = 'Email';
export const notepadPrintButtonTitle = 'Print';
export const notepadCopyButtonTitle = 'Copy';
export const notepadUndoButtonTitle = 'Undo';
export const notepadRdoButtonTitle = 'Redo';
export const notepadGoogleChatButtonTitle = 'Google Chat Share';

export const noOfAccordions = 15;

export const arbitrators = {
    imageUrl: `${imageURL}icon_1655690890.png`,
    pageUrl: 'https://www.toolkit.law/vendors/arbitrators',
    title: 'Arbitrators',
};

export const mediators = {
    imageUrl: `${imageURL}icon_1655691801.png`,
    pageUrl: 'https://www.toolkit.law/vendors/mediators',
    title: 'Mediators',
};

export const mediationMemTem = {
    imageUrl: '/assets/images/notepad/mediation-memorandum-icon.png',
    pageUrl:
        'https://docs.google.com/document/d/1wNxENS4mygFO-VdRvG_Bvd_AFnz-uKTi/edit?usp=share_link&amp;ouid=113717159699757742378&amp;rtpof=true&amp;sd=true',
    title: 'Mediation Memorandum Template',
};

export const adrDirectories = {
    imageUrl: '/assets/images/notepad/adr-dir.png',
    pageUrl: 'https://www.toolkit.law/subpage/adr-directories',
    title: 'ADR Directories',
};

export const adrTraining = {
    imageUrl: '/assets/images/notepad/adr-software.png',
    pageUrl: 'https://www.toolkit.law/subpage/adr-training',
    title: 'Arbitrator & Mediator Certification Training',
};

export const asdrSoftware = {
    imageUrl: '/assets/images/notepad/adr-training.png',
    pageUrl: 'https://www.toolkit.law/subpage/adr-software',
    title: 'Alternative Dispute Resolution Solution',
};

export const ms365url = 'https://www.office.com/';
export const ms365title = 'Microsoft 365';

export const notetakerFields = [
    {
        type: 'text',
        className: 'col-md-12',
        label: 'Title',
        name: 'title',
        placeholder: '',
        required: true,
        isCustomField: true,
        static: true,
    },
    {
        type: 'notepad',
        className: 'col-md-12 notepad',
        label: 'Notes',
        name: 'notepad',
        placeholder: 'Type here, then select the below "Save" icons ...',
        required: false,
    },
];

export const fields = [
    {
        type: 'text',
        className: 'col-md-12',
        label: 'Note Title',
        name: 'title',
        placeholder: '',
        required: true,
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
        static: true,
    },
];

export const defaultTitles = [];
defaultTitles[1] = 'Note 01';
defaultTitles[2] = 'Note 02';
defaultTitles[3] = 'Note 03';
defaultTitles[4] = 'Note 04';
defaultTitles[5] = 'Note 05';
defaultTitles[6] = 'Note 06';
defaultTitles[7] = 'Note 07';
defaultTitles[8] = 'Note 08';
defaultTitles[9] = 'Note 09';
defaultTitles[10] = 'Note 10';
defaultTitles[11] = 'Note 11';
defaultTitles[12] = 'Note 12';
defaultTitles[13] = 'Note 13';
defaultTitles[14] = 'Note 14';
defaultTitles[15] = 'Note 15';

export const formInitialValues = [];

for (let index = 1; index <= noOfAccordions; index++) {
    const title = defaultTitles[index] || ''; // Use the default title or an empty string if it's not available
    formInitialValues[index] = {
        title,
        notepad: '',
    };
}

export const pageData = [];

for (let index = 1; index <= noOfAccordions; index++) {
    // Initialize an object for each index
    pageData[index] = {};

    // Use a switch statement to set properties for each index
    switch (index) {
        case 1:
            pageData[index] = {
                defaultTitle: defaultTitles[index],
                formInitialValues: formInitialValues[index],
                formFields: formFieldswithNotepad(
                    fields,
                    'form1_notepad',
                    'advanced',
                    true,
                ),
            };
            break;
        case 2:
            pageData[index] = {
                defaultTitle: defaultTitles[index],
                formInitialValues: formInitialValues[index],
                formFields: formFieldswithNotepad(
                    fields,
                    'form2_notepad',
                    'advanced',
                    true,
                ),
            };
            break;
        case 3:
            pageData[index] = {
                defaultTitle: defaultTitles[index],
                formInitialValues: formInitialValues[index],
                formFields: formFieldswithNotepad(
                    fields,
                    'form3_notepad',
                    'advanced',
                    true,
                ),
            };
            break;
        case 4:
            pageData[index] = {
                defaultTitle: defaultTitles[index],
                formInitialValues: formInitialValues[index],
                formFields: formFieldswithNotepad(
                    fields,
                    'form4_notepad',
                    'advanced',
                    true,
                ),
            };
            break;
        case 5:
            pageData[index] = {
                defaultTitle: defaultTitles[index],
                formInitialValues: formInitialValues[index],
                formFields: formFieldswithNotepad(
                    fields,
                    'form5_notepad',
                    'advanced',
                    true,
                ),
            };
            break;
        case 6:
            pageData[index] = {
                defaultTitle: defaultTitles[index],
                formInitialValues: formInitialValues[index],
                formFields: formFieldswithNotepad(
                    fields,
                    'form6_notepad',
                    'advanced',
                    true,
                ),
            };
            break;
        case 7:
            pageData[index] = {
                defaultTitle: defaultTitles[index],
                formInitialValues: formInitialValues[index],
                formFields: formFieldswithNotepad(
                    fields,
                    'form7_notepad',
                    'advanced',
                    true,
                ),
            };
            break;
        case 8:
            pageData[index] = {
                defaultTitle: defaultTitles[index],
                formInitialValues: formInitialValues[index],
                formFields: formFieldswithNotepad(
                    fields,
                    'form8_notepad',
                    'advanced',
                    true,
                ),
            };
            break;
        case 9:
            pageData[index] = {
                defaultTitle: defaultTitles[index],
                formInitialValues: formInitialValues[index],
                formFields: formFieldswithNotepad(
                    fields,
                    'form9_notepad',
                    'advanced',
                    true,
                ),
            };
            break;
        case 10:
            pageData[index] = {
                defaultTitle: defaultTitles[index],
                formInitialValues: formInitialValues[index],
                formFields: formFieldswithNotepad(
                    fields,
                    'form10_notepad',
                    'advanced',
                    true,
                ),
            };
            break;
        case 11:
            pageData[index] = {
                defaultTitle: defaultTitles[index],
                formInitialValues: formInitialValues[index],
                formFields: formFieldswithNotepad(
                    fields,
                    'form11_notepad',
                    'advanced',
                    true,
                ),
            };
            break;
        case 12:
            pageData[index] = {
                defaultTitle: defaultTitles[index],
                formInitialValues: formInitialValues[index],
                formFields: formFieldswithNotepad(
                    fields,
                    'form12_notepad',
                    'advanced',
                    true,
                ),
            };
            break;
        case 13:
            pageData[index] = {
                defaultTitle: defaultTitles[index],
                formInitialValues: formInitialValues[index],
                formFields: formFieldswithNotepad(
                    fields,
                    'form13_notepad',
                    'advanced',
                    true,
                ),
            };
            break;
        case 14:
            pageData[index] = {
                defaultTitle: defaultTitles[index],
                formInitialValues: formInitialValues[index],
                formFields: formFieldswithNotepad(
                    fields,
                    'form14_notepad',
                    'advanced',
                    true,
                ),
            };
            break;
        case 15:
            pageData[index] = {
                defaultTitle: defaultTitles[index],
                formInitialValues: formInitialValues[index],
                formFields: formFieldswithNotepad(
                    fields,
                    'form15_notepad',
                    'advanced',
                    true,
                ),
            };
            break;
        default:
            // Handle default case if needed
            break;
    }
}
