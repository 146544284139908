import { Suspense, lazy, useContext, useEffect } from 'react';
import { UserSettingsContext } from '../../../context/UserSettingsContext';
import { Grid } from '@mui/material';
import HeaderIconsSkeleton from '../../skeleton/header/HeaderIconsSkeleton';
import HeaderLogoSkeleton from '../../skeleton/header/HeaderLogoSkeleton';
const MobileLeftHeader = lazy(() => import('./MobileLeftHeader'));
const DesktopLeftHeader = lazy(() => import('./DesktopLeftHeader'));
const DesktopRightHeader = lazy(() => import('./DesktopRightHeader'));

const HeaderLogo = lazy(() => import('../../content/contentLogic/HeaderLogo'));

const Header = (props) => {
    const { siteSetting, adminUrl, isMobile, headerLogo } = props;

    const { userSettings, setAdminSiteUrl } = useContext(UserSettingsContext);

    useEffect(() => {
        if (!adminUrl) return;
        setAdminSiteUrl(adminUrl);
    }, [adminUrl, setAdminSiteUrl]);

    useEffect(() => {}, [headerLogo]);
    return (
        <>
            <Grid
                container
                justifyContent="space-between"
                position="fixed"
                top="0px"
                zIndex="999"
                bgcolor="white"
                sx={{
                    p: {
                        xs: '0rem 0rem .50rem 0rem',
                        sm: '0rem 0rem .25rem .75rem',
                    },
                }}
            >
                <Grid item xs={isMobile ? 2 : 4}>
                    {!isMobile && (
                        <Suspense fallback={<HeaderIconsSkeleton />}>
                            <DesktopLeftHeader
                                userSettings={userSettings}
                                adminUrl={adminUrl}
                            />
                        </Suspense>
                    )}

                    {isMobile && (
                        <Suspense fallback={<HeaderIconsSkeleton />}>
                            <MobileLeftHeader
                                siteSetting={siteSetting}
                                adminUrl={adminUrl}
                            />
                        </Suspense>
                    )}
                </Grid>
                <Grid item xs={isMobile ? 8 : 4} alignItems="center">
                    <Suspense fallback={<HeaderLogoSkeleton />}>
                        {(!isMobile &&
                            siteSetting.toggle_header_center === 1) ||
                        (isMobile &&
                            siteSetting.mobile_header_center_toggle === 1) ? (
                            <HeaderLogo
                                isMobile={isMobile}
                                headerLogo={headerLogo}
                            />
                        ) : null}
                    </Suspense>
                </Grid>

                {
                    //todo: HeaderRight Component
                }
                <Grid item xs={isMobile ? 2 : 4}>
                    <Suspense
                        fallback={
                            <HeaderIconsSkeleton boxWidth="33%" justify="end" />
                        }
                    >
                        <DesktopRightHeader
                            isMobile={isMobile}
                            siteSetting={siteSetting}
                            adminUrl={adminUrl}
                        />
                    </Suspense>
                </Grid>
            </Grid>
        </>
    );
};

export default Header;
