import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <Container
            sx={{
                position: 'relative',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    textAlign: 'center',
                    maxWidth: 500,
                    width: '100%',
                    lineHeight: 1.4,
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        width: 180,
                        height: 180,
                        m: '0 auto 80px',
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            bgcolor: 'primary.main',
                            transform: 'rotate(45deg)',
                            border: '5px dashed #000',
                            borderRadius: '5px',
                        }}
                    />
                    <Typography
                        component="h1"
                        sx={{
                            fontFamily: 'cabin, sans-serif',
                            color: '#000',
                            fontWeight: 700,
                            fontSize: '90px',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        404
                    </Typography>
                </Box>
                <Typography
                    component="h2"
                    sx={{
                        fontFamily: 'cabin, sans-serif',
                        fontSize: '33px',
                        fontWeight: 700,
                        textTransform: 'uppercase',
                        letterSpacing: '7px',
                        mt: '-40px',
                    }}
                >
                    Page Not Found
                </Typography>
                <Typography
                    component="p"
                    sx={{
                        fontFamily: 'cabin, sans-serif',
                        fontSize: '16px',
                        color: '#000',
                        textAlign: 'center',
                        my: 2,
                    }}
                >
                    The page you are looking for might have been removed, had
                    its name changed, or is temporarily unavailable.
                </Typography>

                <Button
                    variant="dark"
                    sx={{
                        my: 4,
                        borderRadius: '50px',
                    }}
                    onClick={() => navigate('/')}
                >
                    Go to Home
                </Button>
            </Box>
        </Container>
    );
};

export default NotFoundPage;
