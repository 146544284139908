export const NumberMessages = {
    type: () => 'Must be a number',
    min: ({ min }) => `Must be at least ${min}`,
    max: ({ max }) => `Must be at most ${max}`,
    between: ({ min, max }) => `Must be between ${min} and ${max}`,
    step: (step, { value }) => {
        const lowerBound = Math.floor(value / step) * step;
        const upperBound = Math.ceil(value / step) * step;
        return `Please enter a valid value. The two nearest valid values are 
        ${lowerBound} and ${upperBound}`;
    },
    integer: ({ value }) => {
        return `Please enter valid number. The two nearest valid values are 
        ${Math.floor(value)} and ${Math.ceil(value)}.`;
    },
};

export const StringMessages = {
    type: () => 'Must be a string',
    min: ({ min }) => `Must be at least ${min} characters`,
    max: ({ max }) => `Must be at most ${max} characters`,
    email: () => 'Must be a valid email',
    url: () => 'Must be a valid URL',
    trim: () => 'Cannot start or end with whitespace',
    lowercase: () => 'Must be lowercase',
    uppercase: () => 'Must be uppercase',
};
