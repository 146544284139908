import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element, ...rest }) => {
    const userPd = localStorage.getItem('userPd');
    if (userPd === '0') {
        return <Navigate to="/settings/billing/plans" />;
    }

    return element;
};

export default ProtectedRoute;
