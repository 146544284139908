import React from 'react';

import { Box, Card, CardContent, Skeleton } from '@mui/material';

const EducationSkeleton = () => {
    const renderSkeletonRow = () => (
        <Box display="flex" mt={1} flexDirection="row" gap={2}>
            <Skeleton variant="rounded" height={40} width={40} />
            <Box width="100%">
                <Skeleton height={20} width="60%" />
                <Skeleton height={20} width="50%" />
                <Skeleton height={20} width="40%" />
            </Box>
        </Box>
    );

    return (
        <Card variant="outlined" sx={{ boxShadow: 4, borderRadius: 4 }}>
            <CardContent>
                <Skeleton height={40} width="50%" />
                {renderSkeletonRow()}
                {renderSkeletonRow()}
            </CardContent>
        </Card>
    );
};

export default EducationSkeleton;
