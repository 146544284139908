import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const ReturnBack = (props) => {
    const { pageTitle } = props;
    const navigate = useNavigate();
    const location = useLocation();

    // Function to get the parent URL
    const getParentUrl = (currentPath) => {
        const pathSegments = currentPath.split('/').filter(Boolean);

        if (pathSegments.length > 1) {
            pathSegments.pop(); // Remove the last segment

            if (
                pathSegments.length > 0 &&
                pathSegments[pathSegments.length - 1] === 'subpage'
            ) {
                return '/'; // If the second-to-last segment is 'subPage', return root
            }
        } else {
            // If there's only one segment or none, return root
            return '/';
        }

        // Join the remaining segments and return the parent URL
        return `/${pathSegments.join('/')}`;
    };

    const handleBackButtonClick = () => {
        const parentUrl = getParentUrl(location.pathname);
        navigate(parentUrl);
    };

    return (
        <div>
            <div className="sticky-top bg-white">
                <h2 className="title text-center">
                    {pageTitle}
                    <button
                        onClick={handleBackButtonClick}
                        className="mt-1 back btn btn-info btn-sm float-left"
                    >
                        <span className="material-icons" title="Back">
                            reply
                        </span>
                    </button>
                </h2>
            </div>
        </div>
    );
};

export default ReturnBack;
