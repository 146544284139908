import React from 'react';
import { Skeleton, Grid, Box, useMediaQuery } from '@mui/material';

const HeaderIconsSkeleton = ({ boxWidth, justify }) => {
    const isSmallScreen = useMediaQuery('(max-width: 768px)');

    const renderBoxes = (count) => (
        <Box display="flex" flexDirection="row" alignItems="center" mb={0.5}>
            {Array.from({ length: count }).map((_, index) => (
                <Skeleton
                    key={index}
                    variant="rectangular"
                    width={25}
                    height={25}
                    sx={{ ml: index > 0 ? 1 : 0 }}
                />
            ))}
        </Box>
    );

    const renderSkeletonGroups = () => (
        <Grid item>
            {renderBoxes(5)}
            {renderBoxes(5)}
            {renderBoxes(5)}
        </Grid>
    );
    return (
        <Grid
            container
            position="fixed"
            width={boxWidth}
            justifyContent={justify}
            top={3}
            pl={'5px'}
            pr={'5px'}
            sx={{ backgroundColor: 'white' }}
        >
            {!isSmallScreen && renderSkeletonGroups()}
        </Grid>
    );
};

export default HeaderIconsSkeleton;
