import React from 'react';
import { Skeleton, Grid, Box, useMediaQuery } from '@mui/material';

const HeaderSkeleton = () => {
    const isSmallScreen = useMediaQuery('(max-width: 768px)');

    const renderBoxes = (count) => (
        <Box display="flex" flexDirection="row" alignItems="center" mb={0.5}>
            {Array.from({ length: count }).map((_, index) => (
                <Skeleton
                    key={index}
                    variant="rectangular"
                    width={25}
                    height={25}
                    sx={{ ml: index > 0 ? 1 : 0 }}
                />
            ))}
        </Box>
    );

    const renderSkeletonGroups = () => (
        <Grid item>
            {renderBoxes(5)}
            {renderBoxes(5)}
            {renderBoxes(5)}
        </Grid>
    );

    return (
        <Grid
            container
            justifyContent={isSmallScreen ? 'center' : 'space-between'}
            alignItems="center"
            position="fixed"
            top={3}
            pl={1}
            pr={1}
            bgcolor="white"
        >
            {!isSmallScreen && renderSkeletonGroups()}

            <Grid item>
                <Skeleton
                    variant="rectangular"
                    width={isSmallScreen ? 200 : 300}
                    height={40}
                />
            </Grid>

            {!isSmallScreen && renderSkeletonGroups()}
        </Grid>
    );
};

export default HeaderSkeleton;
