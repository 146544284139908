import { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import React Quill styles
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
export const defaultQlSize = '20px';

export const getNotakerName = (uniqueIdentifier) => {
    const noteTakerName = 'notaker_';
    // Get the current timestamp
    return noteTakerName + uniqueIdentifier;
};

export const handlePaste = (name, form, setForm) => {
    navigator.clipboard
        .read()
        .then((items) => {
            for (let item of items) {
                if (item.types.includes('text/html')) {
                    item.getType('text/html').then((blob) => {
                        blob.text().then((html) => {
                            const updatedForm = { ...form };
                            const existingText = updatedForm[name] || '';
                            updatedForm[name] = existingText + html;
                            setForm(updatedForm);
                        });
                    });
                    return;
                } else if (item.types.includes('text/plain')) {
                    item.getType('text/plain').then((blob) => {
                        blob.text().then((text) => {
                            const updatedForm = { ...form };
                            const existingText = updatedForm[name] || '';
                            updatedForm[name] = existingText + text;
                            setForm(updatedForm);
                        });
                    });
                    return;
                } else if (
                    item.types.includes('image/png') ||
                    item.types.includes('image/jpeg')
                ) {
                    item.getType(item.types[0]).then((blob) => {
                        const reader = new FileReader();
                        reader.onload = (event) => {
                            const imageUrl = event.target.result;
                            const updatedForm = { ...form };
                            const existingText = updatedForm[name] || '';
                            updatedForm[name] =
                                existingText +
                                `<img src="${imageUrl}" alt="Pasted Image" />`;
                            setForm(updatedForm);
                        };
                        reader.readAsDataURL(blob);
                    });
                    return;
                }
            }
        })
        .catch((err) => {
            console.error('Failed to read clipboard contents: ', err);
        });
};

function handleUndoChange() {
    this.quill.history.undo();
}

function handleRedoChange() {
    this.quill.history.redo();
}

export const qlBasicToolbar = (
    <>
        <span className="ql-formats">
            <select className="ql-size" defaultValue={defaultQlSize}>
                <option value="12px">Size 1</option>
                <option value="16px">Size 2</option>
                <option value="20px">Size 3</option>
                <option value="24px">Size 4</option>
                <option value="28px">Size 5</option>
            </select>
            <select className="ql-header" defaultValue="3">
                <option value="1">Heading</option>
                <option value="2">Subheading</option>
                <option value="3">Normal</option>
            </select>
        </span>
        <span className="ql-formats">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <button className="ql-strike" />
        </span>
        <span className="ql-formats">
            <button className="ql-list" value="ordered" />
            <button className="ql-list" value="bullet" />
            <button className="ql-indent" value="-1" />
            <button className="ql-indent" value="+1" />
        </span>
        <span className="ql-formats">
            <select className="ql-align" />
            <select className="ql-color" />
            <select className="ql-background" />
        </span>
    </>
);

export const qlToolbar = (
    <>
        <span className="ql-formats">
            <select className="ql-size" defaultValue={defaultQlSize}>
                <option value="12px">Size 1</option>
                <option value="16px">Size 2</option>
                <option value="20px">Size 3</option>
                <option value="24px">Size 4</option>
                <option value="28px">Size 5</option>
            </select>
            <select className="ql-header" defaultValue="3">
                <option value="1">Heading</option>
                <option value="2">Subheading</option>
                <option value="3">Normal</option>
            </select>
        </span>
        <span className="ql-formats">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <button className="ql-strike" />
        </span>
        <span className="ql-formats">
            <button className="ql-list" value="ordered" />
            <button className="ql-list" value="bullet" />
            <button className="ql-indent" value="-1" />
            <button className="ql-indent" value="+1" />
        </span>
        <span className="ql-formats">
            <select className="ql-align" />
            <select className="ql-color" />
            <select className="ql-background" />
        </span>
    </>
);

export const CustomUndo = () => <UndoIcon />;

const fontSizeStyle = Quill.import('attributors/style/size');
const qlSizes = ['12px', '16px', '20px', '24px', '28px'];
fontSizeStyle.whitelist = qlSizes;
Quill.register(fontSizeStyle, true);

// Redo button icon component for Quill editor
export const CustomRedo = () => <RedoIcon fontSize="medium" />;

export const getNotepadModules = (notepadName) => {
    const notepadModules = {
        toolbar: {
            container: `#${notepadName}`,
            handlers: {
                redo: handleRedoChange.bind(this),
                undo: handleUndoChange.bind(this),
            },
        },
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true,
        },
    };
    return notepadModules;
};
