import * as Yup from 'yup';
import { notepad } from '../config/fields';
import { createCalculatorSchema } from './YupSchema';

export const formFieldswithNotepad = (
    fields,
    notepadname,
    toolbarType,
    bottomToolbar = false,
    extraTools = false,
) => {
    return [
        ...fields,
        {
            ...notepad({
                name: 'notepad',
                //placeholder: 'Enter Text here...',
                toolbarName: notepadname,
                toolbarType: toolbarType || 'basic',
                noTakerName: notepadname,
                bottomToolbar: bottomToolbar,
                extraTools: extraTools,
            }),
            static: true,
        },
    ];
};

export const createValidationSchema = (fields) => {
    const validationSchemaObject = {};

    fields.forEach(({ fieldName, validationRule }) => {
        let fieldValidationSchema;

        switch (validationRule) {
            case 'four_digit':
                fieldValidationSchema = Yup.number()
                    .typeError('Enter numbers only')
                    .max(9999, 'Account last digit must be a 4-digit number');
                break;
            case 'string':
                fieldValidationSchema = Yup.string();
                break;
            case 'number':
                fieldValidationSchema = Yup.string()
                    .matches(/^[0-9,]+$/, 'Enter numbers only')
                    .test('is-valid-number', 'Enter numbers only', (value) => {
                        // Replace commas with empty string and check if the remaining value is numeric
                        const numericValue = value.replace(/,/g, '');
                        return /^\d+$/.test(numericValue);
                    });
                break;
            case 'email':
                fieldValidationSchema = Yup.string().email(
                    'Enter a valid Email',
                );
                break;
            case 'decimal_number':
                fieldValidationSchema = Yup.string()
                    .matches(/^\d+(\.\d{0,1})?$/, 'Enter a valid number')
                    .transform((value, originalValue) => {
                        if (!originalValue) return ''; // Handle empty input
                        const numericValue = originalValue.replace(
                            /[^\d.]/g,
                            '',
                        ); // Remove non-numeric characters except decimal point
                        return numericValue;
                    });
                break;
            case 'slug':
                fieldValidationSchema = Yup.string()
                    .min(3, 'The user slug must be at least 3 characters long.')
                    .max(
                        100,
                        'The user slug cannot be longer than 100 characters.',
                    )
                    .matches(
                        /^[a-zA-Z0-9_-]+$/,
                        'The user slug may only contain letters, numbers, underscores, and hyphens.',
                    );
                break;
            case 'price':
                fieldValidationSchema = Yup.number()
                    .typeError('Enter a valid number')
                    .positive('Price must be a positive number')
                    .integer('Price must be an integer')
                    .min(1, 'Price must be at least 1')
                    .max(10000, 'Price is too high');

                break;
            default:
                fieldValidationSchema = Yup.string();
                break;
        }

        validationSchemaObject[fieldName] = fieldValidationSchema;
    });

    const validationSchema = Yup.object().shape(validationSchemaObject);

    return validationSchema;
};

export const validateField = async (
    fieldName,
    value,
    validationSchema,
    setErrors,
) => {
    if (validationSchema !== undefined && validationSchema.fields[fieldName]) {
        try {
            // Validate using Yup for the specific field if it exists in the validation schema
            await validationSchema.validateAt(fieldName, {
                [fieldName]: value,
            });

            // If validation passes, clear the error for this field
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: undefined,
            }));
        } catch (error) {
            // If validation fails, set the error for this field
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: error.message,
            }));
        }
    }
};

export const validationSchemaByIndex = (formInputs, selectedIndex) => {
    const selectedFormInputs = selectedIndex.map((index) => formInputs[index]);

    const schemaObject = selectedFormInputs.reduce((acc, input) => {
        acc[input.inputName] = createCalculatorSchema(input);
        return acc;
    }, {});

    return Yup.object().shape(schemaObject);
};
