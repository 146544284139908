import { useContext } from 'react';

import { reactLocalStorage } from 'reactjs-localstorage';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

import { LoaderContext } from '../../../context/LoaderContext';
import { AlertMessageContext } from '../../../context/AlertMessageContext';
import { logError } from '../../../utils/SiteHelpers';
import { handleLogout } from '../../../utils/api';

const LogoutButton = ({ variant = 'contained' }) => {
    const { setShowLoader, setLoaderMessage } = useContext(LoaderContext);
    const { setErrorMessage } = useContext(AlertMessageContext);
    const { user, logout } = useAuth0();

    const handleUserLogout = async () => {
        try {
            setShowLoader(true);
            setLoaderMessage('Clearing local storage and cache...');

            sessionStorage.clear();
            reactLocalStorage.clear();

            await new Promise((resolve) => setTimeout(resolve, 1000));

            setLoaderMessage('Logging out...');

            await handleLogout(user, logout);

            await new Promise((resolve) => setTimeout(resolve, 1000));
        } catch (error) {
            logError('Error during logout:', error);
            setErrorMessage('Failed to log out. Please try again.');
        } finally {
            setShowLoader(false);
        }
    };

    return (
        <Button onClick={handleUserLogout} variant={variant}>
            Logout
        </Button>
    );
};

export default LogoutButton;
