import * as Yup from 'yup';
import { NumberMessages } from './YupErrorMessages';

export const createCalculatorSchema = (inputProps) => {
    const { type, min, max, step, required } = inputProps;
    let schema;
    switch (type) {
        case 'number':
            schema = Yup.number();
            schema = schema
                .typeError(NumberMessages.typeError)
                .transform((value, originalValue) => {
                    if (typeof originalValue === 'object') {
                        originalValue = originalValue.value;
                    }
                    return originalValue === '' ||
                        originalValue === undefined ||
                        isNaN(originalValue)
                        ? undefined
                        : parseFloat(originalValue);
                });
            if (step === undefined) {
                schema = schema.integer(NumberMessages.integer);
            } else if (typeof step === 'number' && step > 0) {
                schema = schema.test(
                    'step',
                    NumberMessages.step.bind(null, step),
                    (value) => {
                        if (value === undefined) {
                            return true; // Ignore step validation for undefined values
                        }

                        const isValidStep = (value - min) % step === 0;
                        return isValidStep;
                    },
                );
            }

            break;
        default: // text
            schema = Yup.string();
            break;
    }

    if (min !== undefined || max !== undefined) {
        if (min !== undefined && max !== undefined)
            schema = schema.min(min).max(max);
        else if (min !== undefined) schema = schema.min(min);
        else if (max !== undefined) schema = schema.max(max);
    }

    if (required) {
        schema = schema.required("Required field.");
    }

    return schema;
};
