import React from 'react';
import { Skeleton } from '@mui/material';

const HeaderLogoSkeleton = () => {
    return (
        <>
            <Skeleton
                variant="rectangular"
                height={40}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: { xs: '200px', sm: '300px' },
                }}
            />
        </>
    );
};

export default HeaderLogoSkeleton;
