import { Skeleton, Grid, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const FooterSkeleton = () => {
    const footerConfigBottom = [
        { count: 1, direction: 'row' },
        { count: 1, direction: 'row' },
        { count: 1, direction: 'row' },
        { count: 1, direction: 'row' },
        { count: 1, direction: 'row' },
    ];
    const footerConfigBottomMobile = [
        { count: 1, direction: 'row' },
        { count: 1, direction: 'row' },
        { count: 1, direction: 'row' },
    ];
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const renderBoxes = (count, direction = 'row') => {
        return Array.from({ length: count }).map((_, index) => (
            <Skeleton
                key={index}
                variant="rectangular"
                width={25}
                height={25}
                sx={{
                    ml: direction === 'row' ? 1 : 0,
                    mt: direction === 'column' ? 1 : 0,
                }}
            />
        ));
    };
    const rendertext = (count) => {
        return Array.from({ length: count }).map((_, index) => (
            <Skeleton
                key={index}
                variant="rectangular"
                width={isSmallScreen ? 30 : 100}
                height={10}
                sx={{ ml: { xs: 1, sm: 2 } }}
            />
        ));
    };
    const renderFooterSkeletons = (config) => {
        return config.map((item, index) => (
            <Grid
                item
                key={index}
                display="flex"
                flexDirection={item.direction}
                alignItems="center"
                xs={4}
                sm={2}
            >
                <Box>{renderBoxes(item.count, item.direction)}</Box>
                <Box>{rendertext(item.count, item.direction)}</Box>
            </Grid>
        ));
    };

    return (
        <>
            {/* Footer-socialmedia icon bar skeleton */}
            <Grid
                sx={{ backgroundColor: 'white' }}
                container
                justifyContent="space-between"
                alignItems="center"
                position="fixed"
                bottom="60px"
                px={1}
            >
                {isSmallScreen ? (
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            my={1}
                        >
                            {renderBoxes(6)}
                        </Box>
                    </Grid>
                ) : null}
                {!isSmallScreen && (
                    <Grid item className="footer-left-social-icons">
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                        >
                            {renderBoxes(3)}
                        </Box>
                    </Grid>
                )}
                <Grid item className="footer-copyright-skeleton">
                    <Skeleton
                        variant="rectangular"
                        width={isSmallScreen ? 300 : 500}
                        height={10}
                        sx={{ textAlign: 'center' }}
                    />
                </Grid>
                {!isSmallScreen && (
                    <Grid item className="footer-right-social-icons">
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                        >
                            {renderBoxes(3)}
                        </Box>
                    </Grid>
                )}
            </Grid>
            {/* Footer toolbar skeleton */}
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                position="fixed"
                bottom="15px"
                px={1}
                sx={{ backgroundColor: 'white' }}
            >
                {isSmallScreen
                    ? renderFooterSkeletons(footerConfigBottomMobile)
                    : renderFooterSkeletons(footerConfigBottom)}
            </Grid>
        </>
    );
};

export default FooterSkeleton;
