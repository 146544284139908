import React, { useState, useEffect } from 'react';
import SplashScreen from './SplashScreen';

const SplashScreenWrapper = ({ children }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a loading period (e.g., 3 seconds)
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1500);

        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return <SplashScreen />;
    }

    return children;
};

export default SplashScreenWrapper;
