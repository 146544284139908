import React from 'react';

import { Box, Card, Skeleton } from '@mui/material';

const HeadingSkeleton = () => {
    return (
        <Card variant="outlined" sx={{ boxShadow: 4, borderRadius: 4 }}>
            {/* Background Rounded Skeleton */}
            <Skeleton variant="rounded" width="100%" height="250px" />

            <Box
                sx={{
                    display: { xs: 'flex', sm: 'block' },
                    flexDirection: { xs: 'column' },
                    position: 'relative',
                    padding: '20px',
                }}
            >
                {/* Circular Skeleton for Profile Picture */}
                <Skeleton
                    variant="circular"
                    width={200}
                    height={200}
                    sx={{
                        position: 'absolute',
                        top: '-100px', // Half of the height to center the circle
                        left: '80px', // Adjust left as needed
                    }}
                />

                {/* Rectangular Skeletons for Text */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        mt: { xs: 12, md: 2 },
                        ml: { xs: 2, md: 6 },
                    }}
                >
                    {/* Adjusted to position below the circle */}
                    <Skeleton sx={{ width: { xs: '85%', sm: '60%' } }} />
                    <Skeleton width="40%" />
                    <Skeleton width="40%" />
                </Box>
            </Box>
        </Card>
    );
};

export default HeadingSkeleton;
